<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { label: 'Manajemen Kupon', href: { name:'admin.coupons' } },
        { label: 'Tambah Kupon', isCurrent: true },
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        margin-bottom="30px"
        justify="space-between"
        align-items="center"
        max-width="540px"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Edit Kupon
        </BaseText>
        <c-flex
          align="center"
          gap="20px"
        >
          <BaseText
            v-if="!isAbleToEditCoupon"
            size-mobile="10px"
            size-desktop="12px"
            text-align="right"
          >
            Masa berlaku kupon sudah dimulai. <br>
            Kupon tidak dapat di hapus.
          </BaseText>
          <BaseButton
            :disabled="!isAbleToEditCoupon"
            :left-svg-icon="require('@/assets/icons/icon-trash.svg')"
            left-svg-icon-color="white"
            color="danger"
            @click="isOpenModalConfirmDeleteCoupon = true"
          >
            Hapus
          </BaseButton>
        </c-flex>
      </c-flex>

      <form
        v-chakra
        width="100%"
        max-width="540px"
      >
        <BaseInputText
          v-model="name"
          label="Nama Kupon"
          placeholder="Masukkan Nama Kupon"
          full-width
          :is-disabled="!isAbleToEditCoupon"
          is-required
          :is-invalid="isInvalidField($v.name)"
          :invalid-text="parseErrors('Nama kupon', $v.name)"
          @blur="$v.name.$touch"
        />
        <BaseInputText
          v-model="code"
          label="Kode"
          placeholder="Masukkan Kode"
          full-width
          :is-disabled="!isAbleToEditCoupon"
          is-required
          :is-invalid="isInvalidField($v.code)"
          :invalid-text="parseErrors('Kode', $v.code)"
          @blur="$v.code.$touch"
        />
        <BaseInputText
          v-model="quota"
          label="Kuota"
          placeholder="Masukkan Kuota"
          full-width
          :is-disabled="!isAbleToEditCoupon"
          is-required
          :is-invalid="isInvalidField($v.quota)"
          :invalid-text="parseErrors('Kuota', $v.quota)"
          @blur="$v.quota.$touch"
        />
        <c-flex
          gap="16px"
        >
          <BaseInputDate
            v-model="startAt"
            type="date"
            label="Start Date"
            placeholder="Pilih Start Date"
            full-width
            :is-disabled="!isAbleToEditCoupon"
            is-required
            :is-invalid="isInvalidField($v.startAt)"
            :invalid-text="parseErrors('Start date', $v.startAt)"
            @blur="$v.startAt.$touch"
          />
          <BaseInputDate
            v-model="endAt"
            type="date"
            label="End Date"
            placeholder="Pilih End Date"
            full-width
            :is-disabled="!isAbleToEditCoupon"
            is-required
            :is-invalid="isInvalidField($v.endAt)"
            :invalid-text="parseErrors('End date', $v.endAt)"
            @blur="$v.endAt.$touch"
          />
        </c-flex>

        <c-flex
          gap="16px"
        >
          <c-box
            max-width="185px"
            width="100%"
          >
            <BaseInputSelect
              v-model="type"
              :is-disabled="!isAbleToEditCoupon"
              label="Tipe"
              :options="[{
                value: 'fix',
                label: 'Fix',
              }, {
                value: 'percent',
                label: '%',
              }]"
              placeholder="Pilih Tipe Kupon"
              is-required
              :is-invalid="isInvalidField($v.type)"
              :invalid-text="parseErrors('Tipe kupon', $v.type)"
              full-width
              @blur="$v.type.$touch"
              @trigger-change="totalDiscount = ''"
            />
          </c-box>
          <BaseInputText
            v-model="totalDiscount"
            label="Jumlah Potongan"
            :placeholder="`Masukkan ${type === 'fix' ? 'Jumlah' : '%'} Potongan`"
            full-width
            :is-disabled="!isAbleToEditCoupon"
            :input-left-addon="type === 'fix' ? 'Rp' : '%'"
            is-required
            :is-invalid="isInvalidField($v.totalDiscount)"
            :invalid-text="parseErrors('Jumlah potongan', $v.totalDiscount)"
            @blur="$v.totalDiscount.$touch"
          />
        </c-flex>

        <BaseInputCheckbox2
          v-model="productIds"
          is-required
          label="Gunakan untuk program"
          :options="listProducts.map((product) => ({
            label: product.name,
            value: product.id,
          }))"
          :is-disabled="!isAbleToEditCoupon"
          :is-invalid="isInvalidField($v.productIds)"
          :invalid-text="parseErrors('Program', $v.productIds)"
          @blur="$v.productIds.$touch"
        >
          <template #default="{ isSelected, option }">
            <c-box
              v-if="isSelected"
              padding-left="28px"
              margin-bottom="100px"
            >
              <BaseInputMultiTag
                v-model="selectedProducts[option.value]"
                :is-loading="isLoadingProducts[option.value]"
                :options="dataProducts[option.value] || []"
                without-invalid-text
                :is-disabled="!isAbleToEditCoupon"
              />
            </c-box>
          </template>
        </BaseInputCheckbox2>

        <c-flex
          mt="28px"
          justify-content="space-between"
          gap="16px"
        >
          <BaseButton
            border-radius="1000px"
            size="large"
            width="100%"
            variant="outlined"
            @click="$router.back()"
          >
            Batal
          </BaseButton>
          <BaseButton
            border-radius="1000px"
            size="large"
            width="100%"
            :disabled="$v.$invalid || !isAbleToEditCoupon"
            @click="isOpenModalConfirmEditCoupon = true"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </form>

      <BaseModal
        :is-open="isOpenModalConfirmEditCoupon"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              src="https://ik.imagekit.io/dietela/pwa_webp/popup/apakah_yakin.png?updatedAt=1690890254662"
              alt="image confirm edit coupon"
            />
          </c-box>
        </template>
        <template #body>
          <c-flex
            justify-content="center"
            padding="30px 24px"
            text-align="center"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Apakah anda yakin ingin melakukan <br>
              perubahan data kupon “{{ name }}”?
            </BaseText>
          </c-flex>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              variant="outlined"
              rounded="1000px"
              width="100%"
              :disabled="isLoadingEditCoupon"
              @click="isOpenModalConfirmEditCoupon = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingEditCoupon"
              @click="onSubmit"
            >
              Oke
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>

      <BaseModal
        :is-open="isOpenModalSuccessEditCoupon"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="160px"
              object-fit="cover"
              src="https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620"
              alt="image success edit coupon"
            />
          </c-box>
        </template>
        <template #body>
          <c-flex
            justify-content="center"
            padding="30px 24px"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Data kupon berhasil diubah!
            </BaseText>
          </c-flex>
        </template>
        <template #footer>
          <c-box
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="isOpenModalSuccessEditCoupon = false"
            >
              Oke
            </BaseButton>
          </c-box>
        </template>
      </BaseModal>

      <BaseModal
        :is-open="isOpenModalConfirmDeleteCoupon"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              src="https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641"
              alt="image confirmation delete coupon"
            />
          </c-box>
        </template>
        <template #body>
          <c-flex
            justify-content="center"
            padding="30px 24px"
            text-align="center"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Anda yakin ingin hapus data kupon “{{ name }}”?
            </BaseText>
          </c-flex>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              variant="outlined"
              rounded="1000px"
              width="100%"
              :disabled="isLoadingDeleteCoupon"
              @click="isOpenModalConfirmDeleteCoupon = false"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="danger"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingDeleteCoupon"
              @click="onDelete"
            >
              Hapus
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>

      <BaseModal
        :is-open="isOpenModalSuccessDeleteCoupon"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="160px"
              object-fit="cover"
              src="https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620"
              alt="image success delete coupon"
            />
          </c-box>
        </template>
        <template #body>
          <c-flex
            justify-content="center"
            padding="30px 24px"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="primary.400"
            >
              Data kupon berhasil dihapus!
            </BaseText>
          </c-flex>
        </template>

        <template #footer>
          <c-box
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="isOpenModalSuccessDeleteCoupon = false"
            >
              Oke
            </BaseButton>
          </c-box>
        </template>
      </BaseModal>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BaseInputText from '@/components/elements/base-input-text.vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import { required, minValue, integer } from 'vuelidate/lib/validators'
import day from '@/utils/day'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import gteDate from '@/utils/vuelidate-custom-validator/gte-date'
import lteDate from '@/utils/vuelidate-custom-validator/lte-date'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseInputMultiTag from '@/components/elements/base-input-multi-tag.vue'
import BaseInputCheckbox2 from '@/components/elements/base-input-checkbox-2.vue'
import _ from 'lodash'
import { parseErrorCatch } from '@/utils/parse-error-catch'

export default {
  name: 'ManagementCouponsForm',
  components: {
    BaseInputCheckbox2,
    BaseInputMultiTag,
    BaseModal,
    BaseText,
    BreadcrumbPath,
    BaseButton,
    BaseInputSelect,
    BaseInputDate,
    BaseInputText,
  },
  mixins: [
    generalMixin,
  ],
  data() {
    return {
      isLoadingDeleteCoupon: false,
      isOpenModalConfirmDeleteCoupon: false,
      isOpenModalSuccessDeleteCoupon: false,
      isLoadingEditCoupon: false,
      isOpenModalConfirmEditCoupon: false,
      isOpenModalSuccessEditCoupon: false,
      name: null,
      code: null,
      type: 'fix',
      totalDiscount: null,
      startAt: null,
      endAt: null,
      quota: null,
      productIds: [],
      listProducts: [],
      productServiceIdsFromInit: [],
      startAtOld: null,
      endAtOld: null,
      isLoadingProducts: {},
      dataProducts: {},
      selectedProducts: {},
    }
  },
  computed: {
    getFilteredSelectedProducts() {
      let result = []
      this.productIds?.forEach((productId) => {
        if (this.selectedProducts[productId]) {
          this.selectedProducts[productId]?.forEach((selectedProduct) => {
            result.push(selectedProduct?.id)
          })
        }
      })
      return result
    },
    getProductIds() {
      return this.productIds
    },
    couponId() {
      return this.$route.params.id
    },
    isAbleToEditCoupon() {
      if (!this.couponId) return false
      const today = new Date()
      const start = new Date(this.startAtOld)
      const end = new Date(this.endAtOld)
      const isBetween = day(today).isBetween(start, end)
      if (isBetween) return false
      const isExpiry = day(today).isAfter(start) && day(today).isAfter(end)
      if (isExpiry) return false
      return true
    },
  },
  watch: {
    getProductIds: {
      immediate: true,
      handler(val) {
        val.forEach((id) => {
          if (!this.dataProducts[id]) {
            this.$set(this.isLoadingProducts, id, true)
          }
        })
      },
    },
    productIds: {
      handler: _.debounce(function(val) {
        val.forEach((id) => {
          if (!this.dataProducts[id]) {
            this.detailProduct(id)
              .then((res) => {
                this.$set(this.dataProducts, id, res?.productServices)
                this.$set(this.selectedProducts, id, res?.productServices.filter((v) => this.productServiceIdsFromInit?.includes(v.id)))
              }).catch((e) => {
                this.$toast({
                  status: 'error',
                  title: 'Error',
                  description: parseErrorCatch(e),
                  duration: 3000,
                })
              })
              .finally(() => {
                this.isLoadingProducts[id] = false
              })
          }
        })
      }, 1200),
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    parseErrors,
    isInvalidField,
    ...mapActions({
      detailCoupons: 'admCoupons/detailCouponsAdmin',
      updateCoupons: 'admCoupons/updateCouponsAdmin',
      deleteCoupons: 'admCoupons/deleteCouponsAdmin',
      detailProduct: 'clients/getProductById',
      loadProducts: 'clients/getProducts',
    }),
    onSubmit() {
      this.isLoadingEditCoupon = true
      let params = {
        name: this.name,
        code: this.code,
        type: this.type,
        totalDiscount: this.totalDiscount,
        startAt: this.startAt,
        endAt: this.endAt,
        quota: this.quota,
        productIds: this.productIds,
        productServiceIds: this.getFilteredSelectedProducts,
      }
      params.id = this.couponId
      return this.updateCoupons(params)
        .then(() => {
          this.isOpenModalSuccessEditCoupon = true
          this.$toast({
            title: 'Success',
            description: 'Update coupon is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.coupons.detail',
            params: { id: this.couponId },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: parseErrorCatch(err),
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).finally(() => {
          this.isLoadingEditCoupon = false
          this.isOpenModalConfirmEditCoupon = false
        })
    },
    onDelete() {
      this.isLoadingDeleteCoupon = true
      this.deleteCoupons(this.couponId)
        .then(() => {
          this.isOpenModalSuccessDeleteCoupon = true
          this.$toast({
            title: 'Success',
            description: 'Kupon has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.coupons',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: parseErrorCatch(err),
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }).finally(() => {
          this.isLoadingDeleteCoupon = false
          this.isOpenModalConfirmDeleteCoupon = false
        })
    },
    async init() {
      const [coupon, products] = await Promise.all([
        this.couponId ? this.detailCoupons(this.couponId) : Promise.resolve({}),
        this.loadProducts(),
      ])
      this.listProducts = products?.data
      this.name = coupon?.data?.name
      this.code = coupon?.data?.code
      this.type = coupon?.data?.type
      this.totalDiscount = coupon?.data?.totalDiscount
      this.startAt = coupon?.data?.startAt
      this.endAt = coupon?.data?.endAt
      this.quota = coupon?.data?.quota
      this.productIds = coupon?.data?.productIds
      this.productServiceIdsFromInit = coupon?.data?.productServiceIds

      this.startAtOld = coupon?.data?.startAt
      this.endAtOld = coupon?.data?.endAt
    },
  },
  validations() {
    return {
      name: { required },
      code: { required },
      quota: { required, integer, minValue: minValue(1) },
      startAt: { required, lteDate: lteDate('endAt', 'End date') },
      endAt: { required, gteDate: gteDate('startAt', 'Start date') },
      type: { required },
      totalDiscount: {
        required,
        ...(this.type === 'fix' ? { integer } : {}),
        minValue: minValue(1),
      },
      productIds: { required },
    }
  },
}
</script>
